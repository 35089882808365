import React from 'react'

function GridCard({
    backgroundImage,
    text
}: any) {
    return (
        <div className=" flex items-center justify-center h-[96px] md:h-[200px] w-full oject-fit bg-center font-bold text-2xl text-white px-4 "
            style={{ 'backgroundImage': `url(${backgroundImage})` }}>
            <p className="shadow-2xl ">

                {text}
            </p>
        </div>
    )
}

export default GridCard