import React from 'react'
import footer_background from "../assets/images/footer-background-overlay.jpg"
import banner from "../assets/banner.png"
import partner1 from '../assets/images/partner-IG.png'
import partner2 from '../assets/images/partner-BA.png'
import partner3 from '../assets/images/partner-GCITT.png'
import partner4 from '../assets/images/partner-MGT.png'
import partner5 from '../assets/images/partner-PMP.png'

function WebsiteFooter() {
    return (
        <footer>
            <div className='shadow-[0_-15px_35px_rgba(0,0,0,0.1)]'>
                <div className="2xl:h-48 items-center 2xl:flex md:px-16  justify-between pt-8 md:pt-0">
                    <div className='lg:flex items-center'>
                        <a href="#">
                            <img src={banner} alt="GOELERS' Logo" className="w-[20rem] py-4 lg:py-0 px-8 md:px-4"></img>
                        </a>


                        <div className='flex flex-col flex-1 justify-center text-justify text-lg py-4 px-8 md:px-4 2xl:max-w-3xl'>
                            <p className=''>Une entreprise locale pour promouvoir des approches locales aux problèmes présents et futurs du continent.</p>
                            <p className="">Nous sommes persuadés que le développement de l'Afrique a comme préalable la disponibilité avérée de l'énergie associée à la mise en œuvre de solutions de déploiement simples, sûres, efficaces et optimisées.</p>

                        </div>
                    </div>
                    <div className=''>
                        <div className='md:hidden text-center font-bold text-2xl pt-4'>
                            <p>
                                Nos partenaires
                            </p>
                        </div>
                        <div className='flex h-full md:justify-end right-0 md:h-32 justify-center items-center py-8'>
                            <div className='flex items-center justify-center md:justify-end text-center font-bold text-2xl md:pr-8 hidden md:flex'>Nos partenaires</div>
                            <img src={partner1} alt="" className='md:pr-8 w-32' />
                            <img src={partner2} alt="" className='md:pr-8 w-32' />
                            <img src={partner3} alt="" className='md:pr-16 w-32' />

                        </div>
                    </div>

                </div>
                <div className="flex h-16 w-[100%] justify-between md:px-24 px-4 bg-center bg-cover bg-[#A54188] items-center" >
                    <p className="text-white md:text-xl">
                        © 2023 GOELERS SOLUTIONS. All rights reserved.
                    </p>

                </div>


            </div>
        </footer>

    )
}

export default WebsiteFooter