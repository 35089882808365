import CTA from "./CTA";
import banner from "../assets/banner.png"
import purple_bg_long from "../assets/images/purple-bg-long.png"
import menu_icon from "../assets/icons/menu.svg"
import close_icon from "../assets/icons/close.svg"
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const navigate = useNavigate()

    const [showMenu, setShowMenu] = useState(false);
    const mobileMenu = () => {
        setShowMenu(!showMenu);
        console.log("Clicked menu")
    }

    return (
        <header className='sticky top-0 z-30 bg-white'>
            <div className='flex justify-between items-center flex-1 md:h-32 h-24'>
                <a href="/" className="md:px-16 px-4">
                    <img src={banner} className="w-[24rem]"></img>
                </a>
                <div className="lg:hidden pr-4 pl-8">
                    <button
                        className="relative"
                        type="submit"
                        onClick={mobileMenu}
                        style={{ 'width': '60px', 'height': '60px' }}>

                        <img
                            className={`absolute inset-0 transition-all ${showMenu ? "opacity-0" : "opacity-100"}`}
                            src={menu_icon} alt="Menu button">
                        </img>
                        <img
                            width={40}
                            className={`absolute left-3 top-3 transition-all ${showMenu ? "opacity-100" : "opacity-0"}`}
                            src={close_icon} alt="Close menu button">
                        </img>
                    </button>

                </div>
                <div className='items-center md:w-[75%] h-full justify-end px-16 hidden lg:flex space-x-2' style={{ "backgroundImage": `url(${purple_bg_long})` }}>

                    <nav className="">
                        <ul className='flex items-center text-white space-x-2'>
                            <a href='/'>
                                <li className='flex px-8 items-center h-12 rounded-xl hover:bg-[#EA5EC1] duration-200 items-center font-bold text-sm xl:text-lg'>
                                    Acceuil
                                </li>
                            </a>
                            <a href='/aboutUs'>
                                <li className='flex px-8 items-center h-12 rounded-xl hover:bg-[#EA5EC1] duration-200 items-center font-bold text-sm xl:text-lg'>
                                    Qui sommes-nous?
                                </li>
                            </a>
                            {/* <a href='#'>
                                <li className='flex px-8 items-center h-24 hover:bg-[#EA5EC1] items-center font-bold text-sm xl:text-lg'>
                                    Nos services
                                </li>
                            </a>
                            <a href='#'>
                                <li className='flex px-8 items-center h-24 hover:bg-[#EA5EC1] items-center font-bold text-sm xl:text-lg'>
                                    Nos projets
                                </li>
                            </a> */}
                        </ul>
                    </nav>
                    <div className=''>
                        <CTA
                            text={"Contactez-nous"}
                            link = {"/contact"}
                        />

                    </div>

                </div>

            </div>

            {true &&
                <div className={`absolute z-10 w-full bg-white transition-all transform ${showMenu ? "opacity-100 visible" : "opacity-0 invisible"}`}>
                    <nav className="">
                        <ul className=''>
                            <a href='/'>
                                <li className='flex px-8 justify-center h-24 hover:bg-[#EA5EC1] duration-200 items-center font-bold text-sm xl:text-lg'>
                                    Acceuil
                                </li>
                            </a>
                            <a href='/aboutUs'>
                                <li className='flex px-8 justify-center h-24 hover:bg-[#EA5EC1] duration-200 items-center font-bold text-sm xl:text-lg'>
                                    Qui sommes-nous?
                                </li>
                            </a>
                            <a href='/contact'>
                                <li className='flex px-8 justify-center h-24 hover:bg-[#EA5EC1] duration-200 items-center font-bold text-sm xl:text-lg'>
                                   Contactez-nous
                                </li>
                            </a>
                            {/* <a href='#'>
                                <li className='flex px-8 items-center h-24 hover:bg-[#EA5EC1] items-center font-bold text-sm xl:text-lg'>
                                    Nos projets
                                </li>
                            </a> */}
                        </ul>

                    </nav>

                </div>}


            <div className='h-4 bg-[#A6A6A6] sticky'></div>
        </header>
    );
};

export default Header;