import React from 'react'

function CTA({ text, link }: any) {
  return (
    <a href={link}>
      <div className='hidden md:flex flex px-4 py-1.5 duration-200 rounded-xl bg-purple-300 items-center justify-center text-center font-bold h-12 bg-[#9D1074] hover:bg-[#EA5EC1] text-white text-sm xl:text-lg'>
        {text}
      </div>
    </a>
  )
}

export default CTA