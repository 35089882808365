import React, { useEffect, useState } from 'react'
import banner from "../assets/banner.png"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import energies from '../assets/slider/energies.jpg'
import energies2 from '../assets/slider/energie2.jpg'
import supervision from '../assets/slider/supervision.jpg'
import industries from '../assets/slider/industries.jpg'
import engineering from '../assets/slider/engineering.jpg'
import audit from '../assets/slider/audit.jpg'
import security from '../assets/slider/security.jpg'

import energies_grid from '../assets/homepage-grid/energies-small.png'
import industries_grid from '../assets/homepage-grid/industries-small.png'
import security_grid from '../assets/homepage-grid/security-small.png'
import engineering_grid from '../assets/homepage-grid/ingenierie-small.png'
import supervision_grid from '../assets/homepage-grid/supervision-small.png'
import audit_grid from '../assets/homepage-grid/audit-small.png'
import engineer from "../assets/images/engineer.png"

import check_mark from "../assets/icons/check-mark.png"
import networking from "../assets/icons/networking.png"
import esprit_dequipe from "../assets/icons/esprit-d-equipe.png"
import communication from "../assets/icons/liberte-d-expression.png"
import purple_bg from "../assets/images/purple-bg.png"

import WebsiteFooter from '../components/WebsiteFooter';
import GridCard from '../components/GridCard';
import ValuesSection from '../components/ValuesSection';
import NewsArticle from '../components/NewsArticle';
import Header from '../components/Header';

import { useNavigate, Link } from 'react-router-dom';
import projects from "../data/projects.json"

// type Project = {

//     title: string;
//     description: string;
//     images: { url: string; description: string; } [];
//     content: string[];

// }

export interface Project {
    title: string;
    description: string;
    images: Image[];
    content: string[];
}

export interface Image {
    url: string;
    description: string;
}

const slideImages = [
    { url: energies2, caption: 'Slide 1', text: "Energies" },
    { url: industries, caption: 'Slide 2', text: "Industries" },
    { url: security, caption: 'Slide 3', text: "Securite" },
    { url: engineering, caption: 'Slide 5', text: "Ingenierie" },
    { url: supervision, caption: 'Slide 4', text: "Supervision & Controle" },
    { url: audit, caption: 'Slide 5', text: "Conseils & Audits" },
];

interface Projects {
    project: Project
}
const properties = {
    duration: 3000,

    transitionDuration: 500,

    infinite: true,
    easing: "ease",
    //indicators: (i) => <div className="indicator">{i + 1}</div>
};
const propertiesProjects = {
    duration: 3000,
    autoplay: false,
    transitionDuration: 500,

    infinite: true,
    easing: "ease",
    //indicators: (i) => <div className="indicator">{i + 1}</div>
};

function Homepage() {

    const navigate = useNavigate()

    const [screenSize, setScreenSize] = useState<number>(window.innerWidth)

    const setMedia = () => {
        setScreenSize(window.innerWidth)
    }

    useEffect(() => {
        setMedia()
        window.addEventListener('resize', setMedia)

        return () => window.removeEventListener('resize', setMedia)
    }, [])

    return (
        <div className='font-ttcommons'>
            <Header />

            <section>
                <div className=''>
                    <div className='h-128 w-full bg-blue-100 relative z-0'>

                        <div className="absolute bg-[#A54188] bg-opacity-20 z-10 text-left md:ml-16 top-[30%] mx-16 sm:mx-32  md:w-96 m-auto left-0 right-0 md:p-8 p-4 hidden md:block">
                            {/* <div className="absolute bg-[#A54188] bg-opacity-20 z-10 text-left bottom-4 m-auto left-0 right-0 p-8 "> */}
                            {/* <div className="text-4xl text-white font-bold">
                                GOELERS SOLUTIONS
                            </div> */}
                            <p className='text-white md:text-3xl'>
                                Société d'ingénierie énergétique, d'expertise sécurité et de l'assistance a l'exploitation industrielle.
                            </p>


                        </div>

                        <div className="absolute bg-[#A54188] bg-opacity-20 z-10 text-left  bottom-4 mx-16  md:w-96 m-auto left-0 right-0 md:p-8 p-4 md:hidden">
                            {/* <div className="absolute bg-[#A54188] bg-opacity-20 z-10 text-left bottom-4 m-auto left-0 right-0 p-8 "> */}
                            {/* <div className="text-4xl text-white font-bold">
                                GOELERS SOLUTIONS
                            </div> */}
                            <p className='text-white text-2xl '>
                                Société d'ingénierie énergétique, d'expertise sécurité et de l'assistance a l'exploitation industrielle.
                            </p>


                        </div>


                        <div className="h-128 mx-auto">
                            <Slide {...properties}>
                                {slideImages.map((slideImage, index) => (
                                    <div className="h-128 flex relative" key={index} >
                                        {/* <div style={{ 'backgroundImage': `url(${slideImage.url})` }}>
                                    <span>{slideImage.caption}</span>
                                </div> */}
                                        <p className='absolute mx-auto right-8 md:right-16 text-right md:top-[42%] w-96 top-1/3 md:text-7xl text-5xl text-white font-bold'>{slideImage.text}</p>
                                        <img className='object-cover h-full w-full h-[32rem]' src={slideImage.url} alt={slideImage.caption} />
                                    </div>
                                ))}
                            </Slide>
                        </div>

                    </div>
                </div>
            </section>

            {/* <section>
                <div className='w-[30rem] text-black'>
                    <p>GOELERS SOLUTIONS est un Groupe d'entreprises africaines multidisciplinaires expertes dans les domaines de l'Ingénierie, du Conseils et du Management de projets pour les secteurs des infrastructures, de l'industrie, de la Sécurité et de l'Environnement.</p>

                    <p className='pt-8'>Nous sommes présents en Afrique de l'Ouest au Ghana, au Togo, au Bénin et en Afrique centrale au Gabon et bientôt au Congo Brazzaville.</p>
                </div>
            </section> */}

            <section>
                <div className='py-12 w-full flex items-center justify-center px-4 md:px-0'>
                    <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <GridCard backgroundImage={energies_grid} text={"ENERGIES"} />
                        <GridCard backgroundImage={industries_grid} text={"INDUSTRIES"} />
                        <GridCard backgroundImage={security_grid} text={"SECURITE"} />
                        <GridCard backgroundImage={engineering_grid} text={"INGENIERIE"} />
                        <GridCard backgroundImage={supervision_grid} text={"SUPERVISION & CONTROLE"} />
                        <GridCard backgroundImage={audit_grid} text={"CONSEILS ET AUDITS"} />

                    </div>
                </div>
            </section>

            <section>
                <div className="flex justify-center mb-8">
                    <img src={banner} className="w-[16rem] md:w-[24rem]" alt="Logo GOELERS Solutions"></img>
                </div>
            </section>

            <section>
                <div className='flex w-full py-8 h-128'>
                    {/* <img src={engineer} alt="An engineer" className='w-[25%] '></img> */}
                    <div className=' bg-cover hidden lg:block hidden object-cover object-left'
                    //np
                    >
                        <img src={engineer} alt="An engineer" className='h-full w-96 object-right object-cover'></img>
                    </div>

                    <div className='mx-auto lg:w-full max-w-3xl space-y-4 md:space-y-0'>
                        <div className="pb-8 font-bold text-4xl w-full text-center">
                            Nos valeurs
                        </div>
                        <div className='md:flex justify-between space-y-4 md:space-y-0 md:space-x-8'>
                            <div className='items-center justify-center space-y-4 '>
                                <div className='flex'>
                                    <ValuesSection value="INTÉGRITÉ" description="Développer un esprit d'intégrité et de citoyenneté" image={check_mark} />
                                </div>
                                <div className='flex'>
                                    <ValuesSection value="COMMUNICATION" description="Percevoir l'importance d'une bonne communication" image={networking} />

                                </div>
                            </div>

                            <div className='space-y-4 ' >
                                <div className='flex'>
                                    <ValuesSection value="ESPRIT D'ÉQUIPE" description="Développer un esprit d'équipe tout en assurant le potentiel individuel" image={esprit_dequipe} />
                                </div>
                                <div className='flex '>
                                    <ValuesSection value="LIBERTÉ D'EXPRESSION" description="Permettre à chacun d'assumer son désaccord" image={communication} />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='text-white hidden lg:block '
                    // style={{ "backgroundImage": `url(${purple_bg})` }}
                    >
                        <img src={purple_bg} alt="Purple background" className='h-full w-96 object-left object-cover'></img>
                    </div>
                </div>

            </section>

            <section className='py-4 bg-gray-100 '>
                <div className='w-full py-8 h-128'>
                    <div className='flex items-center justify-center flex-0.5 text-center font-bold text-4xl'>Nos dernières actualités</div>


                    <div className='w-full flex flex-cols items-center justify-center '>
                        {/* <div className={`grid lg:grid-cols-${Object.keys(projects).length > 3 ? "3" : Object.keys(projects).length} md:grid-cols-${Object.keys(projects).length > 2 ? "2" : Object.keys(projects).length} grid-cols-1 gap-4 m-auto`}>
                            {Object.keys(projects).map((project: string) => {
                                return <div>
                                    <NewsArticle
                                        //@ts-expect-error
                                        project={projects[project]}
                                    />
                                </div>
                            })}

                        </div> */}

                    </div>
                </div>
                <div className="h-128 mx-auto max-w-4xl ">
                    <Slide {...propertiesProjects} slidesToScroll={1} slidesToShow={screenSize > 1024 ? 3 : screenSize < 768 ? 1 : 2} indicators={true}>
                        {Object.keys(projects).map((project: string, index: number) => {
                            return <div className='flex '>
                                <div className='w-full flex items-center justify-center '>
                                    <NewsArticle
                                        //@ts-expect-error
                                        project={projects[project]}
                                    />

                                </div>
                            </div>
                        })}
                    </Slide>
                </div>
            </section>

            {/* <section>
                <div className="w-full h-96 bg-gray-800">
                    <div className='flex items-center justify-center flex-0.5 text-center text-white pt-8 font-bold text-2xl'>Nos partenaires</div>
                    <div>
                        <div className='h-[280px] w-full flex items-center justify-center py-2 px-24 '>

                            
                        </div>
                    </div>
                </div>
            </section> */}

            <WebsiteFooter />


        </div>
    )
}

export default Homepage