import React from 'react'
import head from "../assets/images/energie.jpg"

function PageTitle({title}: any) {
    return (
        <div>
            <section>
                <div className="flex h-64 bg-blue-100 w-full items-center justify-center bg-center bg-cover text-white"
                    style={{ 'backgroundImage': `url(${head})` }}>
                    <h1 className='text-4xl font-bold'>{title}</h1>
                </div>
            </section>
        </div>
    )
}

export default PageTitle