import React, { useCallback, useEffect, useState } from 'react'
import Header from '../components/Header'
import WebsiteFooter from '../components/WebsiteFooter'
import PageTitle from '../components/PageTitle'
import { useLocation } from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';


function ProjectPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const props = useLocation()
    const imageCount = props.state.images.length;
    const images: string[] = [];

    const [currentImage, setCurrentImage] = useState(0);
    const [currentDescription, setCurrentDescription] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index: number) => {
        setCurrentImage(index);
        setCurrentDescription(index)
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return (
        <div>
            <Header />
            <PageTitle
                title={"Nos dernières actualités"} />

            <section className='w-full py-16 px-8'>
                <div className='max-w-3xl m-auto space-y-4 '>
                    <h2 className='font-bold text-2xl text-left'>{props.state.title}</h2>
                    {/* <p className='text-left'>{props.state.content}</p> */}
                    {props.state.content.map((paragraph: any) => {
                        return <p className='text-left text-justify'>{paragraph}</p>
                    })}

                </div>

                <div className={`grid ${imageCount > 4 ? "md:grid-cols-3" : imageCount === 1 ? "md:grid-cols-1": "md:grid-cols-3"}  grid-cols-1 gap-4 max-w-4xl mx-auto mt-8`}>
                    {props.state.images.map((image: any, index: number) => {
                        //populate a separate array with only the links to the images
                        images.push(window.location.origin + image.url)
                        return <div>
                            <div className='m-auto '>
                                <div className=''>
                                    <img src={window.location.origin + image.url} className="m-auto object-center object-cover w-128 h-64 cursor-pointer" alt="Project" onClick={() => {
                                        openImageViewer(index)
                                    }} />
                                </div>
                                <p className=''>
                                    {image.description}
                                </p>
                            </div>
                        </div>
                    })}

                    {isViewerOpen && <div>
                        <ImageViewer
                            src={images}
                            backgroundStyle={{ zIndex: 99999 }}
                            currentIndex={currentImage}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                        />
                        <p className=''
                            style={{ 'zIndex': 999999 }}
                        >{props.state.images[currentDescription]["decription"]}</p>
                    </div>}

                </div>
            </section>
            <WebsiteFooter />
        </div>
    )
}

export default ProjectPage