import React from 'react';
import './App.css';
import Homepage from './pages/Homepage';
import AboutUs from './pages/AboutUs';
import ProjectPage from './pages/ProjectPage';
import { Routes, Route } from 'react-router-dom';
import Contact from './pages/Contact';

function App() {
  return (
    <div className = "App font-ttcommons">
      <Routes>
        <Route path='/' element = {<Homepage/>}/>
        <Route path='/aboutUs' element = {<AboutUs/>}/>
        <Route path='/contact' element = {<Contact/>}/>
        <Route path = "/project" element = {<ProjectPage/>}/>
      </Routes>
    </div>
    
  );
}

export default App;
