import React from 'react'
import Header from '../components/Header'
import WebsiteFooter from '../components/WebsiteFooter'
//import Flag from 'react-flagpack'
import bj from "../assets/images/flagBenin.png"
import tg from "../assets/images/flagTogo.png"
import gh from "../assets/images/flagGhana.png"
import sn from "../assets/images/flagSenegal.png"
import head from "../assets/images/energie.jpg"
import bolt from "../assets/images/bolt.png"
import africa from "../assets/images/africa.png"
//import quote_bg from "../assets/images/quote-bg.png"
import quote_bg from "../assets/images/wind-turbine.jpg"
import engineering from "../assets/images/engineering.png"
import project_management from "../assets/images/project-management.png"
import audit from "../assets/images/audit.png"
import PageTitle from '../components/PageTitle'


function AboutUs() {
    return (
        <div>
            <Header />
            <PageTitle
                title={"Qui Sommes-nous?"} />

            <section className='w-full py-16 px-8'>
                <div className='max-w-3xl m-auto space-y-4 '>
                    <h2 className='font-bold text-2xl'>A PROPOS DE GOELERS SOLUTIONS</h2>
                    <p className='text-jusitfy'>GOELERS SOLUTIONS est un Groupe d'entreprises africaines multidisciplinaires expertes dans les domaines de l'Ingénierie, du Conseils et du Management de projets pour les secteurs des infrastructures, de l'industrie, de la Sécurité et de l'Environnement.</p>
                    <p className='text-jusitfy'>Nous sommes présents en Afrique de l'Ouest au Ghana, au Togo, au Bénin et en Afrique centrale au Gabon et bientôt Senegal.</p>
                </div>

                <div className='w-64 my-8 mx-auto space-y-4 rounded-2xl bg-white drop-shadow-xl px-8 py-4'>
                    <p>Afrique de l'Ouest</p>
                    <div className="flex justify-between items-center">
                        <p className='font-semibold text-xl'>Bénin</p>
                        <img src={bj} width={32} alt="Flag of Benin" />
                    </div>
                    <div className="flex justify-between items-center">
                        <p className='font-semibold text-xl'>Togo</p>
                        <img src={tg} width={32} alt="Flag of Togo" />
                    </div>
                    <div className="flex justify-between items-center">
                        <p className='font-semibold text-xl'>Ghana</p>
                        <img src={gh} width={32} alt="Flag of Ghana" />
                    </div>
                    <div className="flex justify-between items-center">
                        <p className='font-semibold text-xl'>Senegal</p>
                        <img src={sn} width={32} alt="Flag of Senegal" />
                    </div>
                </div>

                <div className='flex m-auto w-full justify-center px-8 max-w-5xl pt-8'>
                    <div className='grid md:grid-cols-2 gap-8 '>
                        <div className='w-full '>
                            <div className='mx-auto'>
                                <img className='m-auto' src={bolt} width={48} alt="Electricity" />
                            </div>
                            <p className='text-jusitfy md:pt-8 pt-4'>
                                Nous sommes persuadés que le développement de l'Afrique a comme préalable la disponibilité avérée de l'énergie et la mise en œuvre de solutions de déploiement simples, sûres, efficaces et optimisées.
                            </p>
                        </div>
                        <div className='w-full'>
                            <div className='mx-auto'>
                                <img className='m-auto' src={africa} width={48} alt="Africa" />
                            </div>
                            <p className='text-jusitfy md:pt-8 pt-4'>
                                Détenu à 100% par des professionnels africains et afro-américains, GOELERS SOLUTIONS est une société dynamique et indépendante qui met au cœur de sa stratégie, le Développement Durable du continent.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

            <section className='py-16 w-full py-16 '>
                <div className="w-full py-48 m-auto text-white bg-center bg-cover px-8"
                    style={{ 'backgroundImage': `url(${quote_bg})` }}>
                    <h1 className='font-bold text-3xl'>“L'énergie de demain s'invente aujourd'hui”</h1>
                    <p>
                        ...est le slogan de l'entreprise.
                    </p>
                    <p>
                        Il annonce entre autre le besoin essentiel d'anticipation dont doit faire preuve l'ingénierie africaine pour tant soit peu réduire les écarts existant entre les pays africains et le reste du monde.
                    </p>
                </div>
            </section>

            <section className='py-16'>
                <div className=''>
                    <h2 className='font-bold text-2xl'>VISION, MISSION, VALEURS</h2>
                    <div className='mt-8 grid md:grid-cols-3 gap-4 max-w-4xl m-auto px-8'>
                        <div className=' mx-auto space-y-4 rounded-2xl bg-white drop-shadow-xl px-8 py-4'>
                            <h4 className="font-bold">NOTRE VISION</h4>
                            <p className='text-justify'>
                                Etre parmi les entreprises leaders (TOP 10) en Afrique dans notre domaine d'activité d'ici à la fin de l'année 2025.
                            </p>
                        </div>
                        <div className=' mx-auto space-y-4 rounded-2xl bg-white drop-shadow-xl px-8 py-4'>
                            <h4 className="font-bold">NOTRE MISSION</h4>
                            <p className='text-justify'>
                                Concevoir, inventer et développer aujourd'hui les solutions énergétiques et infrastructurelles de demain pour les pays africains dans l'optique de garantir le développement durable du continent et créer de la valeur à nos clients.
                            </p>
                        </div>
                        <div className=' mx-auto space-y-4 rounded-2xl bg-white drop-shadow-xl px-8 py-4'>
                            <h4 className="font-bold">NOS VALEURS</h4>
                            <p className='text-justify'>
                                Développer un esprit d'intégrité et de citoyenneté
                                Percevoir l'importance d'une bonne communication
                                Développer un esprit d'équipe tout en assurant le potentiel individuel
                                Permettre à chacun d'assumer son désaccord
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-16 bg-gray-50 px-8'>
                <div className='max-w-3xl m-auto space-y-4 '>
                    <h2 className='font-bold text-2xl'>NOS DOMAINES D'EXPERTISE</h2>
                    <p className='text-jusitfy'>L'expertise de GOELERS SOLUTIONS s'articule autour de l'ingénierie, de la gestion de projets et de l'assistance à la gestion industrielle par du conseil et des audits industriels.</p>
                    <div className='py-8 px-8 w-full m-auto grid md:grid-cols-3 grid-cols-1 gap-4 items-center justify-center'>
                        <div className='drop-shadow-xl bg-white rounded-2xl w-full py-4 '>
                            <div className=" w-8 mx-auto">
                                <img src={engineering} width={32} alt="Africa" />
                            </div>
                            <p className="font-bold">Ingénierie</p>
                        </div>
                        <div className='drop-shadow-xl bg-white rounded-2xl w-full py-4'>
                            <div className=" w-8 mx-auto">
                                <img src={project_management} width={32} alt="Africa" />
                            </div>
                            <p className="font-bold">Gestion de projet
                            </p>
                        </div>
                        <div className='drop-shadow-xl bg-white rounded-2xl w-full py-4'>
                            <div className="h-8 w-8 mx-auto">
                                <img className='object-bottom' src={audit} width={32} alt="Africa" />
                            </div>
                            <p className="font-bold">Conseils et audits</p>
                        </div>
                    </div>
                    <p className='text-jusitfy'>Dans cette configuration, l'ingénierie représente plus de la moitié de l'activité de l'entreprise quel que soit la zone géographique considérée. Cependant, nous nous efforçons à étendre notre expertise vers d'autres domaines tels que les négociations de contrats et l'intermédiation dans notre secteur d'activité</p>
                </div>
            </section>

            <section className='py-16 px-8'>
                <div className='max-w-3xl m-auto space-y-4 '>
                    <h2 className='font-bold text-2xl'>NOS QUALIFICATIONS</h2>
                    <p className='text-jusitfy'>GOELERS SOLUTIONS est une des entreprises qui ambitionnent de faire partie des leaders dans le domaine de l'ingénierie et du Conseils en Afrique. Nous offrons un grand éventail de services à destinations des industries et du service public. Nous fournissons à nos clients des services sur mesure allant de la définition des idées de projets jusqu'à l'opérationnalisation en passant par la supervision et le contrôle de la mise en œuvre.</p>
                    <div className='py-8 grid md:grid-cols-4 grid-cols-2 items-center justify-center gap-4 m-auto'>
                        <div className='flex justify-center items-center border-yellow-500 border-4 bg-white rounded-full w-32 h-32 m-auto'>
                            <p>Ingénierie</p>
                        </div>
                        <div className='flex justify-center items-center border-green-500 border-4 bg-white rounded-full w-32 h-32 m-auto'>
                            <p>Etudes</p>
                        </div>
                        <div className='flex justify-center items-center border-blue-500 border-4 bg-white rounded-full w-32 h-32 m-auto'>
                            <p>Supervision et contrôle
                            </p>
                        </div>
                        <div className='flex justify-center items-center border-orange-500 border-4 bg-white rounded-full w-32 h-32 m-auto'>
                            <p>Assistance a l'exploitation
                            </p>
                        </div>

                    </div>
                    <p className='text-jusitfy'>GOELERS SOLUTIONS a emmagasiné de multiples références de projets impliquant des partenaires multiculturels et a l'habitude de travailler en consortium ou en sous-traitance avec d'autres entreprises de même ou d'envergure supérieurs dans l'optique de garantir les meilleurs rapports qualité prix à ses clients.
                        La capitalisation de l'expérience de son équipe constituée intégralement d'experts seniors avec une longue pratique du domaine du secteur de l'énergie et une longue expérience et connaissance des infrastructures électriques donnent un avantage avéré à GOELERS
                    </p>
                </div>
            </section>

            <section className='py-16 bg-gray-50 px-8'>
                <div className='max-w-3xl m-auto space-y-4  '>
                    <h2 className='font-bold text-2xl'>NOS EXPERIENCES</h2>
                    <p className='text-jusitfy'>
                        Nos expériences s'étalent principalement sur la partie subsaharienne du continent africain. En effet, au Début, nous avons conclus la plupart de nos contrats avec les grands acteurs du secteur de l'énergie au Ghana. Aujourd'hui, nous avons mis en oeuvres notre expertise au Ghana, au Togo, au Benin, au Mali, au Burkina Faso et bientot au Senegal.
                    </p>
                    <p>
                        Nous avons en effet livré de nombreux projets d'étude de faisabilité d'installations de centrale électriques et des accessoires de mise en œuvre tels que les lignes d'évacuation, les postes de transformation et les sites de dispatching. Nous avons réalisé également la supervision et le contrôle d'autres projets similaires ou de plus grande envergure pour les grands noms du secteur de l'énergie et du gaz naturels.
                    </p>
                </div>
            </section>
            <WebsiteFooter />
        </div>
    )
}

export default AboutUs