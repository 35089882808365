import React from 'react'

function ValuesSection({
    value,
    description,
    image
}: any) {
    return (
        <div className='flex'>
            <div className='w-12 h-12 object-fit bg-cover' style={{ "backgroundImage": `url(${image})` }}></div>
            <div className='pl-8'>
                <div className="font-bold text-left ">{value}</div>
                <p className='sm:w-64 w-48 text-left'>{description}</p>
            </div>

        </div>

    )
}

export default ValuesSection