import React from 'react'
import Header from '../components/Header'
import PageTitle from '../components/PageTitle'
import WebsiteFooter from '../components/WebsiteFooter'

function Contact() {
    return (
        <div className='flex flex-col font-ttcommons min-h-screen'>
            <Header />
            <PageTitle title={"Contactez-nous"} />
            <div className=' grow py-16 max-w-xl mx-auto'>
                <p>
                    Vous pouvez nous joindre via nos numeros de telephone ou par e-mail.
                </p>
                <div className='grid grid-cols-1 md:grid-cols-2 m-auto'>
                    <div className='my-4'>
                        <p className='font-bold'>
                            Benin
                        </p>
                        <a href='tel:+22961666101'>
                            +229 61 66 61 01
                        </a>

                    </div>
                    <div className='my-4'>
                        <p className='font-bold'>
                            Ghana
                        </p>
                        <a href='tel:+233246863156'>
                            +233 24 686 3156
                        </a>
                    </div>
                    <div className='my-4'>
                        <p className='font-bold'>
                            Togo
                        </p>
                        <a href='tel:+22899175150'>
                            +228 99 17 51 50
                        </a>

                    </div>
                    <div className='my-4'>
                        <p className='font-bold'>
                            Senegal
                        </p>
                        <a href='tel:+221766387343'>
                        +221 766387343
                        </a>

                    </div>

                </div>
                <div>
                    <p className='font-bold'>
                        E-mail
                    </p>
                    <a href='mailto:contact@goelersolutions.com'>
                    contact@goelersolutions.com
                    </a>
                </div>
            </div>
            <WebsiteFooter />
        </div>
    )
}

export default Contact