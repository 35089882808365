import React from 'react'
import { Link } from "react-router-dom";


function NewsArticle({
    project
}: any) {

    return (
        <Link preventScrollReset={false} to={"/project"} state={project}>

            <div className='flex justify-end flex-col w-72 h-72  bg-blue-100 bg-cover'
                // style={{ 'backgroundImage': `url(${project.images[0].url})` }}>
                style={{ 'backgroundImage': `url(${project.images[0].url})` }}>
                <div className='w-full h-full px-4 flex pt-40 justify-end items-center bg-gradient-to-t from-gray-600'>
                    <div className='font-bold text-white'>
                        {project.title}
                    </div>
                    {/* <p className='text-sm pt-4 text-white'>
                        {project.description}
                    </p> */}
                </div>

            </div>
        </Link>

    )
}

export default NewsArticle